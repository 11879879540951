import {
  CredentialResponse,
  GoogleOAuthProvider,
  GoogleLogin,
} from "@react-oauth/google";
import { Alert, Container } from "react-bootstrap";
import Image from "next/image";

import { WhoAmI } from "types/app";
import { postAPI } from "api";

import type { components } from "api/openapi";
import { useState } from "react";

type CredentialSubmission = components["schemas"]["CredentialSubmission"];

function submitCredentials(credentials: CredentialSubmission): Promise<WhoAmI> {
  return postAPI("/api/auth/credentials", credentials);
}

function AuthButton({
  onSuccess,
  onPending,
  onError,
}: {
  onSuccess: (whoami: WhoAmI) => void;
  onPending: () => void;
  onError: (message: string) => void;
}) {
  const handleSuccess = (response: CredentialResponse) => {
    onPending();

    const credential = response.credential!;

    submitCredentials({ credential })
      .then((whoami: WhoAmI) => onSuccess(whoami))
      .catch((err) => onError(err.message));
  };

  const handleError = () => {
    onError("Google login failed.");
  };

  return <GoogleLogin onSuccess={handleSuccess} onError={handleError} />;
}

function SignInPage({
  clientId,
  onSuccess,
}: {
  clientId: string;
  onSuccess: (whoami: WhoAmI) => void;
}) {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <Container id="layout-login">
        <h2>
          <Image
            className="me-3"
            src="/static/logo-mark-yellow.svg"
            alt="Artificial Agency Logo"
            width={30}
            height={30}
            priority
            style={{
              filter: "brightness(0.2) grayscale(1)",
            }}
          />
          Sign In
        </h2>

        {errorMessage && (
          <Alert variant="warning">Failed to sign in: {errorMessage}</Alert>
        )}

        <AuthButton
          onSuccess={onSuccess}
          onPending={() => setErrorMessage(null)}
          onError={(message) => setErrorMessage(message)}
        />
      </Container>
    </GoogleOAuthProvider>
  );
}

export default SignInPage;
