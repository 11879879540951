import { ReactNode, useEffect, useState } from "react";

import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Navbar, Spinner, Dropdown } from "react-bootstrap";
import Link from "next/link";
import Image from "next/image";

import type { WhoAmI, NeedsLogin } from "types/app";
import AvatarPopover from "./AvatarPopover";
import { EnvironmentBadge, Sidebar } from "@bagel-web/components";
import { getIsActive, getNavConfig } from "utils/navConfig";
import { initUserContext } from "@bagel-web/common";
import { postAPI } from "api";
import SignInPage from "./SignInPage";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // do not automatically retry failed queries. that's surprising behaviour, and
      // kind of annoying when developing.
      retry: 0,
    },
  },
});

TimeAgo.addDefaultLocale(en);

function ViewAsHeader({
  whoami,
  onLogOut,
}: {
  whoami: WhoAmI;
  onLogOut: () => void;
}) {
  const handleSignOut = () => {
    onLogOut();
    return false;
  };

  return (
    <Navbar className="bg-danger px-3" id="header">
      <b>
        You are viewing the dashboard as {whoami.organization.name}.{" "}
        <a href="#" onClick={handleSignOut} className="text-light">
          Sign out
        </a>
      </b>
    </Navbar>
  );
}

function SwitchOrganizations({
  whoami,
  onSwitchOrganization,
}: {
  whoami: WhoAmI;
  onSwitchOrganization: (shortName: string) => void;
}) {
  if (whoami.organizations.length <= 1) {
    return <></>;
  }

  return (
    <Dropdown className="d-inline me-2" align="end">
      <Dropdown.Toggle
        variant="success"
        id="dropdown-basic"
        style={{ border: "none", background: "none" }}
      />

      <Dropdown.Menu>
        {whoami.organizations.map((org) => {
          return (
            <Dropdown.Item
              key={org.short_name}
              as="button"
              onClick={() => onSwitchOrganization(org.short_name)}
            >
              {org.name}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}

function Header({
  whoami,
  onLogOut,
  onSwitchOrganization,
}: {
  whoami: WhoAmI;
  onLogOut: () => void;
  onSwitchOrganization: (shortName: string) => void;
}) {
  return (
    <Navbar className="navbar-dark bg-dark px-3" id="header">
      <Navbar.Brand>
        <Link href="/" className="navbar-brand">
          <Image
            className="me-3"
            src="/static/logo-mark-yellow.svg"
            alt="Artificial Agency Logo"
            width={30}
            height={30}
            priority
            style={{ verticalAlign: "top" }}
          />
          {whoami.organization.name}
        </Link>

        <SwitchOrganizations
          whoami={whoami}
          onSwitchOrganization={onSwitchOrganization}
        />

        <EnvironmentBadge />
      </Navbar.Brand>
      <Navbar.Collapse className="justify-content-end">
        <AvatarPopover whoami={whoami} onLogOut={onLogOut} />
      </Navbar.Collapse>
    </Navbar>
  );
}

function ClientLayout({ children }: { children: ReactNode }) {
  const [whoAmI, _setWhoAmI] = useState<WhoAmI | null>(null);
  const [needsLogin, setNeedsLogin] = useState<NeedsLogin | null>(null);

  const setWhoAmI = (currentUser: WhoAmI) => {
    initUserContext(
      currentUser.superuser?.id || currentUser.user.id,
      currentUser.organization.short_name,
    );
    _setWhoAmI(currentUser);
  };

  const fetchCurrentSession = () => {
    fetch("/api/auth/whoami")
      .then((res) => {
        if (res.status === 200)
          res.json().then((whoami: WhoAmI) => setWhoAmI(whoami));
        else if (res.status === 401)
          res
            .json()
            .then((needsLogin: NeedsLogin) => setNeedsLogin(needsLogin));
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchCurrentSession();
  }, []);

  const logOut = () => {
    fetch("/api/auth/log-out", { method: "POST" })
      .then(() => {
        _setWhoAmI(null);
        fetchCurrentSession();
      })
      .catch((err) => console.log(err));
  };

  const switchOrganization = (shortName: string) => {
    postAPI<WhoAmI>("/api/auth/switch-organization", { short_name: shortName })
      .then((currentUser) => {
        setWhoAmI(currentUser);

        // invalidate all of our caches
        queryClient.invalidateQueries({ queryKey: [] });
      })
      .catch((err) => console.log(err));
  };

  if (!whoAmI) {
    if (!needsLogin) {
      return (
        <div id="layout-loading">
          <Spinner />
        </div>
      );
    } else if (!whoAmI) {
      return (
        <SignInPage
          clientId={needsLogin.client_id}
          onSuccess={(whoami) => _setWhoAmI(whoami)}
        />
      );
    }
  } else {
    return (
      <QueryClientProvider client={queryClient}>
        <div className="vh-100 d-flex flex-column align-items-stretch">
          {whoAmI.superuser && (
            <ViewAsHeader whoami={whoAmI} onLogOut={logOut} />
          )}
          <Header
            whoami={whoAmI}
            onLogOut={logOut}
            onSwitchOrganization={switchOrganization}
          />
          <div
            className="d-flex flex-row align-items-stretch"
            style={{ flexGrow: 1, flexShrink: 1, overflow: "hidden" }}
          >
            <Sidebar
              config={getNavConfig()}
              LinkComponent={Link}
              getIsActive={getIsActive}
            />
            <main style={{ flexGrow: 1, overflow: "hidden" }}>{children}</main>
          </div>
        </div>
      </QueryClientProvider>
    );
  }
}

export default ClientLayout;
